import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpStatusInterceptor implements HttpInterceptor {

    constructor(
        private toastr: ToastrService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            // tap(event => console.log(event) ),
            catchError(err => {
                this.notifyError(err);
                return throwError(err);
            })
        );
    }

    private notifyError(err) {
        console.log(err);
        let msg = err.message;
        if (err.error) {
            let i = 1;
            if (typeof err.error !== 'string') {
                Object.keys(err.error).forEach(function (key) {
                    msg += ' [' + i + '] ' + key + ': ' + err.error[key];
                    i++;
                });
            } else {
                msg += err.error;
            }

        }
        this.toastr.error(msg, 'Errore ' + err.status, {timeOut: 15000}); // , bodyMaxLength: 1000
    }
}


