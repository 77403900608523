import { Injectable } from '@angular/core';
import * as json2csv from 'json2csv'; // convert json file to csv
import { saveAs } from 'file-saver';  // save the file


@Injectable()
export class DownloadCsvService {
  Json2csvParser = json2csv.Parser;
  constructor() {

  }
  public downloadFile(data: any, opts, filename = 'data.csv') {
    const csvData = this.convertToCSV(data, opts);
    const file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    saveAs(file, filename);
  }



  public convertToCSV(objArray: any, opts) {
    const json2csvParser = new this.Json2csvParser(opts);
    const csv = json2csvParser.parse(objArray);
    console.log(csv);
    return csv;
  }
}
