<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
  <a class="navbar-brand" href="#"></a>
  <button class="navbar-toggler d-md-down-none mr-auto" type="button" appSidebarToggler>
    <span class="navbar-toggler-icon"></span>
  </button>
<!--  <button class="navbar-toggler d-md-down-none" type="button" appAsideMenuToggler>
    <span class="navbar-toggler-icon"></span>
  </button>-->
  <div class="btn-group" role="group" dropdown placement="bottom left">
    <button type="button" class="btn btn-link dropdown-toggle text-dark" dropdownToggle>
      <i class="icon-user"></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right mr-2" *dropdownMenu aria-labelledby="simple-dropdown">
      <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
      <a class="dropdown-item" href="#" (click)="authenticationService.logout()"><i class="fa fa-sign-out-alt"></i> Logout</a>
    </div>
  </div>
</header>
