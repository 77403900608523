<aside class="aside-menu">
  <!--
  <tabset>
    <tab>
      <ng-template tabHeading><i class="icon-list"></i></ng-template>
      <div class="callout m-0 py-2 text-muted text-center bg-light text-uppercase">
        <small><b>Today</b></small>
      </div>
      <hr class="transparent mx-3 my-0">
      <div class="callout callout-warning m-0 py-3">
        <div class="avatar float-right">
          <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
        </div>
        <div>Meeting with <strong>Lucas</strong></div>
        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>
        <small class="text-muted"><i class="icon-location-pin"></i>&nbsp; Palo Alto, CA </small>
      </div>
      <hr class="mx-3 my-0">
      <div class="callout callout-info m-0 py-3">
        <div class="avatar float-right">
          <img src="assets/img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
        </div>
        <div>Skype with <strong>Megan</strong></div>
        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 4 - 5pm</small>
        <small class="text-muted"><i class="icon-social-skype"></i>&nbsp; On-line </small>
      </div>
      <hr class="transparent mx-3 my-0">
      <div class="callout m-0 py-2 text-muted text-center bg-light text-uppercase">
        <small><b>Tomorrow</b></small>
      </div>
      <hr class="transparent mx-3 my-0">
      <div class="callout callout-danger m-0 py-3">
        <div>New UI Project - <strong>deadline</strong></div>
        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 10 - 11pm</small>
        <small class="text-muted"><i class="icon-home"></i>&nbsp; creativeLabs HQ </small>
        <div class="avatars-stack mt-2">
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/2.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/3.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
        </div>
      </div>
      <hr class="mx-3 my-0">
      <div class="callout callout-success m-0 py-3">
        <div><strong>#10 Startups.Garden</strong> Meetup</div>
        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 1 - 3pm</small>
        <small class="text-muted"><i class="icon-location-pin"></i>&nbsp; Palo Alto, CA </small>
      </div>
      <hr class="mx-3 my-0">
      <div class="callout callout-primary m-0 py-3">
        <div><strong>Team meeting</strong></div>
        <small class="text-muted mr-3"><i class="icon-calendar"></i>&nbsp; 4 - 6pm</small>
        <small class="text-muted"><i class="icon-home"></i>&nbsp; creativeLabs HQ </small>
        <div class="avatars-stack mt-2">
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/2.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/3.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/4.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/5.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/6.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
          <div class="avatar avatar-xs">
            <img src="assets/img/avatars/8.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
          </div>
        </div>
      </div>
      <hr class="mx-3 my-0">
    </tab>
    <tab>
      <ng-template tabHeading><i class="icon-speech"></i></ng-template>
      <div class="p-3">
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left">
            <div class="avatar">
              <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
              <span class="avatar-status badge-success"></span>
            </div>
          </div>
          <div>
            <small class="text-muted">Lukasz Holeczek</small>
            <small class="text-muted float-right mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
          <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
        </div>
        <hr>
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left">
            <div class="avatar">
              <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
              <span class="avatar-status badge-success"></span>
            </div>
          </div>
          <div>
            <small class="text-muted">Lukasz Holeczek</small>
            <small class="text-muted float-right mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
          <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
        </div>
        <hr>
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left">
            <div class="avatar">
              <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
              <span class="avatar-status badge-success"></span>
            </div>
          </div>
          <div>
            <small class="text-muted">Lukasz Holeczek</small>
            <small class="text-muted float-right mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
          <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
        </div>
        <hr>
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left">
            <div class="avatar">
              <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
              <span class="avatar-status badge-success"></span>
            </div>
          </div>
          <div>
            <small class="text-muted">Lukasz Holeczek</small>
            <small class="text-muted float-right mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
          <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
        </div>
        <hr>
        <div class="message">
          <div class="py-3 pb-5 mr-3 float-left">
            <div class="avatar">
              <img src="assets/img/avatars/7.jpg" class="img-avatar" alt="admin@bootstrapmaster.com">
              <span class="avatar-status badge-success"></span>
            </div>
          </div>
          <div>
            <small class="text-muted">Lukasz Holeczek</small>
            <small class="text-muted float-right mt-1">1:52 PM</small>
          </div>
          <div class="text-truncate font-weight-bold">Lorem ipsum dolor sit amet</div>
          <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt...</small>
        </div>
      </div>
    </tab>
    <tab>
      <ng-template tabHeading><i class="icon-settings"></i></ng-template>
      <div class="p-3">
        <h6>Settings</h6>

        <div class="aside-options">
          <div class="clearfix mt-4">
            <small><b>Option 1</b></small>
            <label class="switch switch-text switch-pill switch-success switch-sm float-right">
              <input type="checkbox" class="switch-input" checked>
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 2</b></small>
            <label class="switch switch-text switch-pill switch-success switch-sm float-right">
              <input type="checkbox" class="switch-input">
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
          <div>
            <small class="text-muted">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</small>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 3</b></small>
            <label class="switch switch-text switch-pill switch-success switch-sm float-right">
              <input type="checkbox" class="switch-input">
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <div class="aside-options">
          <div class="clearfix mt-3">
            <small><b>Option 4</b></small>
            <label class="switch switch-text switch-pill switch-success switch-sm float-right">
              <input type="checkbox" class="switch-input" checked>
              <span class="switch-label" data-on="On" data-off="Off"></span>
              <span class="switch-handle"></span>
            </label>
          </div>
        </div>

        <hr>
        <h6>System Utilization</h6>

        <div class="text-uppercase mb-1 mt-4"><small><b>CPU Usage</b></small></div>
        <div class="progress progress-xs">
          <div class="progress-bar bg-info" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small class="text-muted">348 Processes. 1/4 Cores.</small>

        <div class="text-uppercase mb-1 mt-2"><small><b>Memory Usage</b></small></div>
        <div class="progress progress-xs">
          <div class="progress-bar bg-warning" role="progressbar" style="width: 70%" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small class="text-muted">11444GB/16384MB</small>

        <div class="text-uppercase mb-1 mt-2"><small><b>SSD 1 Usage</b></small></div>
        <div class="progress progress-xs">
          <div class="progress-bar bg-danger" role="progressbar" style="width: 95%" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small class="text-muted">243GB/256GB</small>

        <div class="text-uppercase mb-1 mt-2"><small><b>SSD 2 Usage</b></small></div>
        <div class="progress progress-xs">
          <div class="progress-bar bg-success" role="progressbar" style="width: 10%" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <small class="text-muted">25GB/256GB</small>
      </div>
    </tab>
  </tabset>
  -->
</aside>
