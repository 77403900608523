export const navigation = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        icon: 'icon-speedometer'
    },
    {
        name: 'Persone',
        url: '/persons',
        icon: 'icon-people',
        children: [
            {
                name: 'Cerca',
                url: '/persons',
                icon: 'icon-magnifier'

            },
            {
                name: 'Crea nuova',
                url: '/persons/edit',
                icon: 'icon-plus'
            },
            {
                name: 'Gestione similarità',
                url: '/persons/akas',
                icon: 'icon-user-following'
            }
        ]
    },
    {
        name: 'Organizzazioni',
        url: '/organizations',
        icon: 'icon-organization',
        children: [
            {
                name: 'Cerca',
                url: '/organizations',
                icon: 'icon-magnifier'
            },
            {
                name: 'Crea nuova',
                url: '/organizations/edit',
                icon: 'icon-plus'
            }
        ]
    },
    {
        name: 'Incarichi',
        url: '/memberships',
        icon: 'icon-graduation',
        children: [
            {
                name: 'Cerca',
                url: '/memberships',
                icon: 'icon-magnifier'
            },
            {
                name: 'Crea nuova',
                url: '/memberships/edit',
                icon: 'icon-plus'
            }
        ]
    },
    {
        name: 'Aree',
        url: '/areas',
        icon: 'icon-direction',
        children: [
            {
                name: 'Cerca',
                url: '/areas',
                icon: 'icon-magnifier'
            },
            {
                name: 'Crea nuova',
                url: '/areas/edit',
                icon: 'icon-plus'
            }
        ]
    },
    {
        name: 'Eventi',
        url: '/keyevents',
        icon: 'icon-event',
        children: [
            {
                name: 'Cerca',
                url: '/keyevents',
                icon: 'icon-magnifier'
            }
        ]
    },
    {
      name: 'Risultati elettorali',
      url: '/electoral_results',
      icon: 'icon-badge'
    },
    /*
    {
        name: 'Similarità',
        url: '/areas',
        icon: 'icon-question',
        children: [
            {
                name: 'Cerca',
                url: '/areas',
                icon: 'icon-magnifier'
            },
            {
                name: 'Crea nuova',
                url: '/areas/edit',
                icon: 'icon-plus'
            }
        ]
    },
    {
        name: 'Test',
        url: '/test/edit',
        icon: 'icon-support'
    }
      {
          name: 'Pages',
          url: '/pages',
          icon: 'icon-star',
          children: [
              {
                  name: 'Login',
                  url: '/pages/login',
                  icon: 'icon-star'
              },
              {
                  name: 'Register',
                  url: '/pages/register',
                  icon: 'icon-star'
              },
              {
                  name: 'Error 404',
                  url: '/pages/404',
                  icon: 'icon-star'
              },
              {
                  name: 'Error 500',
                  url: '/pages/500',
                  icon: 'icon-star'
              }
          ]
      }*/
];
