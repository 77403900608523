import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';

import {JwtHelperService} from '@auth0/angular-jwt';


import {AppConfig} from '../app.config';
import {APICommonService} from '../_services/api-common.service';
import {indexOf} from 'lodash-es';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class AuthenticationService {

  private JWT_service = new JwtHelperService();


  constructor(private http: HttpClient,
              private config: AppConfig,
              private router: Router,
              public apiService: APICommonService,
              private toastr: ToastrService,
  ) {

  }

  public login(username: string, password: string) {

    return this.http.post(this.config.API.authURL, {username: username, password: password}).pipe(
      map((response: any) => {

        if (response.token) {

          const decodedToken = this.JWT_service.decodeToken(response.token);
          const groups = decodedToken['https://openpolis.io/jwt/claims']['groups'];

          if (indexOf(groups, 'opdm_redazione') !== -1) {

            localStorage.setItem('OPDM_currentUser', JSON.stringify(response));

            /* this.http.get( this.config.API.baseURL + 'persons', {params: params}).subscribe( (mydata: any) => {
                console.log(mydata);
            });*/
          } else {
            this.toastr.error(null, 'Utente non redattore.');
          }
        }
        // login successful if there's a jwt token in the response
        /*                const user = response.json();
                        if (user.data && user.data.token) {
                            // store user details and jwt token in local storage to keep user logged in between page refreshes
                            localStorage.setItem('OPDM_currentUser', JSON.stringify(user.data));
                            // this.apiService.setUserCapabilities();
                        }*/
      }));
  }

  public logout() {
    // remove user from local storage to log user out
    // this.apiService.purgeCache();
    localStorage.removeItem('OPDM_currentUser');
    this.router.navigate(['/pages/login']);
  }
}
