import {BrowserModule} from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import {DatePipe} from '@angular/common';
import {FormBuilder} from '@angular/forms';

import {AppComponent} from './app.component';

// Import locale
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

// Import containers
import {
    FullLayoutComponent,
    SimpleLayoutComponent
} from './containers';


import { httpInterceptorProviders } from './_http-interceptors/index';

const APP_CONTAINERS = [
    FullLayoutComponent,
    SimpleLayoutComponent
];

// Import components
import {
    AppAsideComponent,
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
} from './components';

const APP_COMPONENTS = [
    AppAsideComponent,
    AppBreadcrumbsComponent,
    AppFooterComponent,
    AppHeaderComponent,
    AppSidebarComponent,
    AppSidebarFooterComponent,
    AppSidebarFormComponent,
    AppSidebarHeaderComponent,
    AppSidebarMinimizerComponent,
    APP_SIDEBAR_NAV
];

// Import directives
import {
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES,
} from './directives';

const APP_DIRECTIVES = [
    AsideToggleDirective,
    NAV_DROPDOWN_DIRECTIVES,
    ReplaceDirective,
    SIDEBAR_TOGGLE_DIRECTIVES,
];

import { AppConfig } from './app.config'
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService } from './_services/authentication.service';
import { APICommonService } from './_services/api-common.service';
import { HttpClientModule } from '@angular/common/http';

/*import { AppCommonSharedModule } from './shared/pipes-directives.shared.module';*/

// Import routing module
import {AppRoutingModule} from './app.routing';

// Import 3rd party components
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';


// Import only required Font Awesome icons

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {faIdCard, faTrashAlt, faEdit, faQuestionCircle, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import {faAt, faPlus, faMinus, faChevronRight, faChevronDown, faColumns, faCheck, faPencilAlt,
        faUser, faUserPlus, faUserEdit, faUserCheck, faCircleNotch, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';

import { faPiggyBank, faEuroSign, faUsers } from '@fortawesome/pro-light-svg-icons';
import {faFacebookSquare, faTwitterSquare} from '@fortawesome/free-brands-svg-icons';


library.add(
    faIdCard, faTrashAlt, faEdit, faQuestionCircle, faEye, faEyeSlash, faPiggyBank,
    faAt, faPlus, faMinus, faChevronRight, faChevronDown, faColumns, faCheck, faPencilAlt,
    faUser, faUserPlus, faUserEdit, faUserCheck, faCircleNotch, faEuroSign, faUsers,
    faFacebookSquare, faTwitterSquare, faSignOutAlt
);
dom.watch();

import { ToastrModule } from 'ngx-toastr';

import {DownloadCsvService} from './_services/download-csv.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot(
          {
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            progressBar: true
          }
        )
    ],
    declarations: [
        AppComponent,
        ...APP_CONTAINERS,
        ...APP_COMPONENTS,
        ...APP_DIRECTIVES
    ],
    providers: [
        AppConfig,
        AuthGuard,
        AuthenticationService,
        APICommonService,
        DownloadCsvService,
        DatePipe,
        FormBuilder,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        httpInterceptorProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
