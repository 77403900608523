
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../app.config';
// import { APICommonService } from '../_services/api-common.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { indexOf } from 'lodash-es';

@Injectable()
export class AuthGuard implements CanActivate {

    private JWT_service = new JwtHelperService();

    constructor(
        private config: AppConfig,
        private http: HttpClient,
        private router: Router) { }

    public canActivate() {
        const JWT_token = localStorage.getItem('OPDM_currentUser');
        if (JWT_token && !this.JWT_service.isTokenExpired(JWT_token)) {
          const decodedToken = this.JWT_service.decodeToken(JWT_token);
          const groups = decodedToken['https://openpolis.io/jwt/claims']['groups'];

          // only let users belonging to the opdm_redazione group log in
          if (indexOf(groups, 'opdm_redazione') !== -1) {
            this.refreshToken(JWT_token);
            return true;
          }
        }

        /*  const decodedToken = this.JWT_service.decodeToken(JWT_token);
        const expirationDate = this.JWT_service.getTokenExpirationDate(JWT_token);
        const isExpired = this.JWT_service.isTokenExpired(JWT_token);
        console.log(decodedToken, expirationDate, isExpired);*/

        // not logged in so redirect to login page
        this.router.navigate(['/pages/login']);
        return false;
    }

    private refreshToken(JWT_token: string) {
        const JWT = JSON.parse(JWT_token);
        // console.log(JWT);
        return this.http.post( this.config.API.authRefreshURL,  JWT ).pipe(
            map((response: any) => {
                if (response.token) {
                    // console.log(response);
                    localStorage.setItem('OPDM_currentUser', JSON.stringify(response));
                }
            })).subscribe();
    }
}
