// VENDOR MODULES
import {FormlyFieldConfig, FormlyFormOptions, FieldArrayType} from '@ngx-formly/core';

// INTERFACE DEFINITIONS
export interface ListTPL {
    title: string;
    columns: ColumnsTPL[];
    filters: FormlyFieldConfig[];
    actions: FormlyFieldConfig[];
    options: ListTPLOptions;
    prefetch: any
}

export class ListTPLOptions {
    supports_creation?: boolean;
    creation_params?: any;
    csv_export_opts?: any;
    row_detail?: {
        title: string;
        type: string;
        rowHeight?: any;
        datasource?: any;
        datasourceParams?: any;
        fields?: Array<any>;
        fieldGroup?: any;
        fieldArray?: FieldArrayType;
    };

    constructor() {
        this.supports_creation = true;
    }
}

export interface FormTPL {
    title: string;
    fields: FormlyFieldConfig[];
    options: FormlyFormOptions;
}

type HeaderTemplates = 'hdr_exp' | 'hdr' | 'hdr_sel';

export interface ColumnsTPL {
    name: string;
    prop: string;
    head: HeaderTemplates;
    headerCheckboxable?: boolean,
    checkboxable?: boolean,
    cell: any;
    resize: 0 | 1;
    sort: 0 | 1;
    drag: 0 | 1;
    flexGrow: number;
    width?: number;
    canAutoResize?: 0 | 1;
    sortBy?: string;
}
