// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const environment = {
    production: false,
    API: {
      /* dev */
      /*
      baseURL: 'http://localhost:8001/v1/',
      authURL: 'http://localhost:8001/api-token-auth/',
      authRefreshURL: 'http://localhost:8001/api-token-refresh/'
      */

      /* staging */
      baseURL: 'https://staging.service.opdm.openpolis.io/api-mappepotere/v1/',
      authURL: 'https://staging.service.opdm.openpolis.io/api-token-auth/',
      authRefreshURL: 'https://staging.service.opdm.openpolis.io/api-token-refresh/'
    }
};
