import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {NgSelectConfig} from '@ng-select/ng-select';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(
      private router: Router,
      selectConfig: NgSelectConfig
  ) {
      // @ng-select/ng-select configuration
      selectConfig.placeholder = 'Seleziona';
      selectConfig.notFoundText = 'Elementi non trovati';
      selectConfig.addTagText = 'Aggiungi';
      selectConfig.typeToSearchText = 'Scrivi per cercare';
      selectConfig.loadingText = 'Caricamento...';
      selectConfig.clearAllText = 'Rimuovi tutto';
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
}
